import { useState } from 'react'

import { Space, Button, Tooltip, Input }   from 'antd'
import { useIntl } from 'react-intl'

import { CopyOutlined }        from '../../icons';
import { EyeOpenSolid, EyeSlashSolid } from '../../icons';


export function PassphraseViewer (props: { passphrase: string }) {
  const { $t } = useIntl()
  const { passphrase } = props
  const copyButtonTooltipDefaultText = $t({ defaultMessage: 'Copy Passphrase' })
  const copyButtonTooltipCopiedText = $t({ defaultMessage: 'Passphrase Copied' })
  const [copyButtonTooltip, setCopyTooltip] = useState(copyButtonTooltipDefaultText)
  const passwordIconRender = (visible: boolean) => {
      return visible ? <EyeSlashSolid size='sm' data-testid='EyeSlashSolid' />
        : <EyeOpenSolid size='sm'  data-testid='EyeOpenSolid'/>
    }
  return (
    <Space direction='horizontal' size={2} onClick={(e)=> {e.stopPropagation()}}>
      <Input.Password readOnly style={{ paddingLeft: 0}}
      value={passphrase}
        {...props}
      iconRender={passwordIconRender}
      />
      <Tooltip title={copyButtonTooltip}>
        <Button
          icon={<CopyOutlined size='sm' />}
          onMouseOut={() => setCopyTooltip(copyButtonTooltipDefaultText)}
          onClick={() => {
            navigator.clipboard.writeText(passphrase)
            setCopyTooltip(copyButtonTooltipCopiedText)
          }}
        />
      </Tooltip>
    </Space>
  )
}
